import { useDeleteOrgPermanently } from '@hooks/mutations/orgs/useDeleteOrgPermanently';
import { useOrgSettings, useOrgSettingsUpsert } from '@hooks/queries/useOrgSettings';
import { Switch } from '@ui/switch';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'sonner';

import FlaggedFeature from '@/components/FlaggedFeature';
import { Button } from '@/components/ui/button/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';
import { SETTINGS_ORGANIZATION_MEMBERS } from '@/routes/paths';
import { getOrgMembership, useUser } from '@/stores/useUser';
import { FlagKey } from 'lib/flags/keys';
import { SlaSettingKey, SlackTicketReactionSettingKey } from 'lib/models/org_settings';
import { SandboxSettingKey, TestingModeSettingKey } from 'lib/models/org_settings';
import { Clock } from 'lucide-react';
import { Trash2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import { SectionHeader } from '../Approvals/Approvals';
import { EscalationSetting } from './EscalationSetting';
import { OrgChartSettings } from './OrgChartSetting';

const General = () => {
    const { data: testModeSetting, isLoading: isTestModeLoading } = useOrgSettings(TestingModeSettingKey);
    const { data: sandboxSetting, isLoading: isSandboxLoading } = useOrgSettings(SandboxSettingKey);
    const { data: slaSetting } = useOrgSettings<{ hours: number }>(SlaSettingKey);
    const { data: slackTicketReactionSetting } = useOrgSettings(SlackTicketReactionSettingKey);
    const hours = slaSetting?.hours;
    const [slaHours, setSlaHours] = useState(hours);
    const { user } = useUser.getState();
    const nerfed = getOrgMembership(user)?.nerfed;
    const upsertSetting = useOrgSettingsUpsert(TestingModeSettingKey);
    const upsertSlaSetting = useOrgSettingsUpsert<{ hours: number }>(SlaSettingKey);
    const upsertSlackTicketReactionSetting = useOrgSettingsUpsert(SlackTicketReactionSettingKey);
    const [isTestModeEnabled, setIsTestModeEnabled] = useState(testModeSetting?.enabled);
    const [isSlackTicketReactionEnabled, setIsSlackTicketReactionEnabled] = useState(
        slackTicketReactionSetting?.enabled,
    );
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [deleteConfirmation, setDeleteConfirmation] = useState('');
    const deleteOrgMutation = useDeleteOrgPermanently();

    const isLoading = isTestModeLoading || isSandboxLoading;
    const isSandboxEnabled = sandboxSetting?.enabled || false;

    const toggleTestMode = useCallback(
        async (enabled: boolean) => {
            setIsTestModeEnabled(enabled); // Immediately update UI
            try {
                await upsertSetting.mutateAsync({ enabled });
            } catch (_error) {
                setIsTestModeEnabled(!enabled); // Revert UI if mutation fails
                toast.error('Failed to update test mode');
            }
        },
        [upsertSetting], // Removed queryClient from dependencies
    );
    const toggleSlackTicketReaction = useCallback(
        async (enabled: boolean) => {
            setIsSlackTicketReactionEnabled(enabled);
            try {
                await upsertSlackTicketReactionSetting.mutateAsync({ enabled });
            } catch (_error) {
                setIsSlackTicketReactionEnabled(!enabled);
                toast.error('Failed to update Slack ticket reaction setting');
            }
        },
        [upsertSlackTicketReactionSetting],
    );

    // Update local state when query data changes
    useEffect(() => {
        if (slackTicketReactionSetting) {
            setIsSlackTicketReactionEnabled(slackTicketReactionSetting.enabled);
        }
    }, [slackTicketReactionSetting]);

    useEffect(() => {
        if (testModeSetting) {
            setIsTestModeEnabled(testModeSetting.enabled);
        }
    }, [testModeSetting]);

    useEffect(() => {
        if (slaSetting?.hours) {
            setSlaHours(slaSetting.hours);
        }
    }, [slaSetting]);

    const updateSlaHours = useCallback(
        async (hours: number) => {
            setSlaHours(hours);
            try {
                await upsertSlaSetting.mutateAsync({ hours });
            } catch (_error) {
                toast.error('Failed to update SLA hours');
            }
        },
        [upsertSlaSetting],
    );
    const handleDeleteOrg = async () => {
        if (deleteConfirmation !== 'DELETE') return;

        await deleteOrgMutation.mutateAsync();
        setShowDeleteDialog(false);
        window.location.reload();
    };

    return (
        <div className="flex flex-col gap-xl">
            <div className="flex items-start gap-lg">
                {isSandboxEnabled ? (
                    <Tooltip>
                        <TooltipTrigger>
                            <Switch checked={true} disabled={true} />
                        </TooltipTrigger>
                        <TooltipContent>
                            <p>
                                You are currently running Console in a sandbox environment. To enable Console for your
                                entire organization, please reach out to your contact at Console.
                            </p>
                        </TooltipContent>
                    </Tooltip>
                ) : (
                    <Switch checked={isTestModeEnabled} onCheckedChange={toggleTestMode} disabled={isLoading} />
                )}
                <div>
                    <h2 className="font-medium">Enable test mode</h2>
                    <p className="text-xs text-body-subtle-hover">
                        Enabling test mode will restrict Slack bot access to{' '}
                        <Link
                            className="font-medium text-body-blue-primary hover:text-body-blue-primary-hover"
                            to={SETTINGS_ORGANIZATION_MEMBERS}
                        >
                            Console members
                        </Link>
                    </p>
                </div>
            </div>
            <div className="flex flex-col gap-lg">
                <div className="flex items-start gap-lg">
                    <Switch
                        checked={isSlackTicketReactionEnabled}
                        onCheckedChange={toggleSlackTicketReaction}
                        disabled={isLoading}
                    />
                    <div>
                        <h2 className="font-medium">Enable Slack ticket reaction</h2>
                        <p className="text-xs text-body-subtle-hover">
                            Enabling this setting will automatically react to requests created in Slack with a relevant
                            emoji
                        </p>
                    </div>
                </div>
            </div>
            <EscalationSetting />
            <OrgChartSettings />
            <FlaggedFeature flag={FlagKey.ResponseTimeSla}>
                <section className="border-grey border rounded-md">
                    <SectionHeader
                        heading="Response Time SLA"
                        description="The SLA response time that users should expect when a human is looped in to help with their request"
                        icon={<Clock />}
                        iconBg="bg-bg-blue-secondary text-body-white"
                    />
                    <div className="flex flex-col p-lg gap-lg">
                        <div className="flex gap-md items-center">
                            <Input
                                type="number"
                                min={1}
                                className="w-24"
                                placeholder="2"
                                value={slaHours}
                                onChange={e => {
                                    updateSlaHours(Number(e.target.value));
                                }}
                            />
                            <Label className="text-body-subtle-hover">hours</Label>
                        </div>
                    </div>
                </section>
            </FlaggedFeature>

            {nerfed && (
                <>
                    <section className="border-grey border rounded-md">
                        <SectionHeader
                            heading="Delete Organization"
                            description="This action cannot be undone"
                            icon={<Trash2 />}
                            iconBg="bg-bg-red-secondary text-body-white"
                        />
                        <div className="flex flex-col gap-md p-lg">
                            <Button variant="red" onClick={() => setShowDeleteDialog(true)}>
                                Delete Organization
                            </Button>
                        </div>
                    </section>

                    <Dialog open={showDeleteDialog} onOpenChange={setShowDeleteDialog}>
                        <DialogContent>
                            <DialogHeader>
                                <DialogTitle>Delete Organization</DialogTitle>
                                <DialogDescription>
                                    This action cannot be undone. This will permanently delete your organization and
                                    remove all associated data.
                                </DialogDescription>
                            </DialogHeader>
                            <div className="p-4">
                                <p className="mb-2 text-sm">
                                    Please type <span className="font-medium">DELETE</span> to confirm
                                </p>
                                <Input
                                    value={deleteConfirmation}
                                    onChange={e => setDeleteConfirmation(e.target.value)}
                                    placeholder="Type DELETE to confirm"
                                />
                            </div>
                            <DialogFooter>
                                <Button variant="grey" onClick={() => setShowDeleteDialog(false)}>
                                    Cancel
                                </Button>
                                <Button
                                    variant="red"
                                    onClick={handleDeleteOrg}
                                    disabled={deleteConfirmation !== 'DELETE' || deleteOrgMutation.isPending}
                                >
                                    {deleteOrgMutation.isPending ? 'Deleting...' : 'Delete Organization'}
                                </Button>
                            </DialogFooter>
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default General;
